<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <b-img
            :src="require('@/assets/images/logo/logo.png')"
            v-bind="mainProps"
          />-->

          <h2 class="brand-text text-primary ml-1">
            Paulo Lopes
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Plataforma Serviços ! 👋
        </b-card-title>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="user.email"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="email"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <!-- <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{name:'forgotPassword'}">
                  <small>Esqueceu a Password?</small>
                </b-link>
              </div>-->
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="user.password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Lembrar-me
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="login()"
            >
              Entrar
            </b-button>
            <b-alert
              :show="alert"

              variant="warning"
              type="error"
            >
              <h4 class="alert-heading">
                Atenção
              </h4>
              <div class="alert-body">
                <span>A sua conta ainda não se encontra ativa. Por favor entre em contacto com o TREINA-ME</span>
              </div>
            </b-alert>
          </b-form>
        </validation-observer>

        <!-- <b-card-text class="text-center mt-2">
          <span>É novo na nossa plataforma? </span>
          <b-link :to="{name:'register'}">
            <span>Crie aqui a sua conta</span>
          </b-link>
        </b-card-text>-->

        <!-- <div class="divider my-2">
          <div class="divider-text">
            ou
          </div>
        </div> -->

        <!-- social button -->
        <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            href="javascript:void(0)"
            variant="facebook"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="twitter"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="google"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="github"
          >
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div> -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BInputGroup, BInputGroupAppend, BFormCheckbox, BAlert,

} from 'bootstrap-vue'

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import axios from 'axios'
import swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      mainProps: {
        width: 98, height: 98,
      },
      status: '',
      alert: false,
      // validation rules
      required,
      email,
      user: {
        email: '',
        password: '',
      },
      userSigned: {
        id: '',
        nome: '',
        email: '',
        profile: [],
      },

      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  methods: {
    login() {
      // for (let i = 0; i < this.userSigned.profile.length; i += 1) {
    //   if (this.userSigned.profile[i] === 'ADMIN' || this.userSigned.profile[i] === 'STAFF') {
    //     this.isAdmin = true
    //     this.isCustomer = false
    //   } else if ((this.userSigned.profile[i] === 'Customer') || (this.userSigned.profile[i] === 'Cliente')) {
    //     this.isAdmin = false
    //     this.isCustomer = true
    //   }
    // }
      axios.get(`/api/v1/userProfiles/email/${this.user.email}`)

        .then(resLogin => {
          if (resLogin.data.length > 0) {
            if (resLogin.data[0].administrador === 1) {
              axios.post('loginStaff', this.user)
                .then(res => {
                  this.userSigned.nome = res.data.nome
                  this.userSigned.email = res.data.email
                  this.userSigned.id = res.data.idUser
                  this.userSigned.profile = res.data.profiles
                  localStorage.setItem('userSigned', JSON.stringify(this.userSigned))
                  localStorage.setItem('jwt', res.data.jwtAccessToken)
                  this.headers = {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                  }

                  // this.getUserProfile()
                  this.getUserSubscription()

                // window.location.href = 'home'
                }).catch(error => {
                  if (error.response.status === 401) {
                    this.error401()
                  } else if (error.response.status === 404) {
                    this.error404()
                  } else {
                    this.error()
                  }
                })
            } else {
              axios.post('loginUsers', this.user)
                .then(res => {
                  this.userSigned.nome = res.data.nome
                  this.userSigned.email = res.data.email
                  this.userSigned.id = res.data.idUser

                  localStorage.setItem('userSigned', JSON.stringify(this.userSigned))
                  localStorage.setItem('jwt', res.data.jwtAccessToken)
                  this.headers = {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                  }
                  axios.get('/api/v1/users/me', { headers: this.headers })

                    .then(resA => {
                      if (resA.data.active === 0) {
                        axios.get('/api/v1/subscriptions/me', { headers: this.headers })
                          .then(ress => {
                            if (ress.data.length === 0) {
                              this.$router.push('subscription')
                            } else {
                              this.errorActive()
                            }
                          })
                      } else {
                        this.getUserSubscription()
                      }
                    })
                }).catch(error => {
                  if (error.response.status === 401) {
                    this.error401()
                  } else if (error.response.status === 404) {
                    this.error404()
                  } else {
                    this.error()
                  }
                })
            }
          } else {
            this.error404()
          }
        })
    },

    getUserSubscription() {
      this.$router.push('home')
    },
    error401() {
      swal.fire({
        title: 'Erro!',
        text: 'Email ou Password errado!',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    errorActive() {
      swal.fire({
        title: 'Erro!',
        text: 'Utilizador Inativo!',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    error404() {
      swal.fire({
        title: 'Erro!',
        text: 'Email não encontrado!',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    error() {
      swal.fire({
        title: 'Erro!',
        text: 'Problemas na autenticação!',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
